import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './CourseSelect.css'
import axios from 'axios'
import { CourseData } from '../../../App'

const CourseSelect = () => {
    const [allCourse,setAllCourse]=useState([])
    const [allCourseCount,setAllCourseCount]=useState([])
    const [selectCourse,setSelectCourse,latestTools,setLatestTools,popularTools,setPopularTools] = useContext(CourseData)

    const handleCourse = async() => {
        const {data} = await axios.get('https://api.cit.tools/api/frontendApi/getAlldepartmentsTools')
        setAllCourse(data?.departments)
        setAllCourseCount(data?.totalTools)
        setSelectCourse(data?.departments)
        setLatestTools(data?.latestTools)
        setPopularTools(data?.popularTools)
    }
    useEffect(() => {
        handleCourse()
    }, [])
    return (
        <>
    <div className="courseSelectBg">
        <div className="container courseMain">
            <div className="courseHeader">
                <h2>পছন্দের কোর্সে ক্লিক করুন এবং আনলিমিটেড সফটওয়্যার, টুলস ও রিসোর্স ব্যবহারের সুযোগ নিন</h2>
                <p>শুধুমাত্র  cit শিক্ষার্থীদের জন্যই থাকছে ২০ লক্ষ+ টাকার প্রিমিয়াম টুলস, সফটওয়্যার ও রিসোর্স অসংখ্যবার ডাউনলোড এর দারুণ সুযোগ</p>
            </div>
            <div className="signleCourse">
          
               {
                   allCourse.map((signleCourse,index)=>
                
               <div className="courseItems" key={index} >
               <Course key={index} signleCourse={signleCourse}/>
               </div>
               )
               }
               {/* <Link to="/digital-marketing" className="courseItems">
                   <div >
                   <Course img={course4} title="Digital Marketing"/>
                   </div>
               </Link>
               <Link to="/web-design" className="courseItems"><div className="coursemarginright">
               <Course img={course2} title="Web Design"/>
               </div></Link>
               <Link to="/3d-animation" className="courseItems">
                   <div >
                   <Course img={course3} title="3D Animation"/>
                   </div>
               </Link> */}
              
              
            </div>
            
             
           <div className="text-center">
           <div className="MaterialsCount">
           <div className="courseItems">
           <CourseCount countTitle={allCourseCount+"+"} title="Premium Assets"/>
           </div>
           <div className="courseItems">
           <CourseCount countTitle="20,00,000+" title="Taka Resources"/>
           </div>
           <div className="courseItems">
           <CourseCount countTitle="2,000+" title="Custom Mockups"/>
           </div>
           <div className="courseItems">
           <CourseCount countTitle="Unlimited" title="Downloads Access"/>
           </div>
        </div>
           </div>
        </div>
 </div>       
        </>
    )
}
export default CourseSelect;

export const Course=({signleCourse})=>{
    const {id,name} = signleCourse;
    // console.log(postId)
    return(
        <>
        <Link to={`/${id}/${name}`} > 
        <div className="courses" >
            <div>
                <div className="courseImgItem" style={{backgroundImage:`url(${signleCourse?.deptThumbnail})`}}></div>
                <p>{signleCourse?.name}</p>
            </div>
        </div>
        </Link>
        </>
    )
}

export const CourseCount=({title,countTitle})=>{
    return(
        <>
         <div className="count ">
                <h4>{countTitle}</h4>
                <p>{title}</p>
            </div>
        </>
    )
}