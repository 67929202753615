import React,{useState, useEffect} from 'react'
import {Link, useParams} from "react-router-dom";
// import {Code} from 'react-content-loader'
import axios from 'axios'
import Category  from'../Category/Category'
 import  CategoryGetting from '../CategoryGetting/CategoryGetting'
import Footer from '../Footer/Footer'
 import AllMaterials from '../Home/AllMaterials/AllMaterials'
import AllTipTicks from '../Home/AllTipTicks/AllTipTicks'
import CourseSelect from '../Home/CourseSelect/CourseSelect'
import VideoTalk from '../Home/VideoTalk/VideoTalk'

 const CourseDetails = () => {
  const {postId}=useParams();
  console.log(postId,"heloo")
  const [allCourseDetails,setAllCourseDetails]=useState({})
  const handleCourse = async() => {
      const {data}= await axios.get(`https://api.cit.tools/api/frontendApi/department/${postId}`)
    if(data){
      setAllCourseDetails(data?.department)
    }
  }
  useEffect(() => {
      handleCourse()
      console.log(postId)
  },[postId])
  console.log(allCourseDetails?.heroVideo)
    return (
        <>
          <VideoTalk 
          h1title={allCourseDetails?.heroOne}
          Inputvideo={allCourseDetails?.heroVideo}
          title="প্রজেক্টগুলো দারুণভাবে সম্পন্ন করার জন্য যা যা প্রয়োজন হবে"
          // title1="get creative projects done"
            text={allCourseDetails?.heroDescription}
            name="ok"
            courseName={allCourseDetails?.name}
            bannerClass={true}
            bannerImg={allCourseDetails?.heroBackground}
          />
            <CategoryGetting allCourseDetails={allCourseDetails}/>
        
          
         {allCourseDetails?.categories_for_department?.map((singleCat,index)=><Category singleCat={singleCat} key={index}/>)}
         {/* <Link className="allSeeBtn">See All Categories</Link> */}
          <AllMaterials
           title11=" জনপ্রিয় টুলস" title22="নতুন সফটওয়্যারগুলো" title33="নতুন যা কিছু যুক্ত করা হয়েছে"
          />
          <CourseSelect/>
          <AllTipTicks/>
          <Footer/>
        </>
    )
}
export default CourseDetails;
