import React from 'react'
import './CategoryGetting.css'
import ShowMoreText from "react-show-more-text";
import { Link } from 'react-router-dom';

 const CategoryGetting = (
    {
    allCourseDetails    
    }
) => {
    
    const handleSign=()=>{
        window.location.assign("https://app.cit.tools/");
    }
    return (
        <>
          <div className="categorygetting">
              <div className="container">
                  <div className="getting">
                      <h3>{allCourseDetails?.descriptionHeader}</h3>
                    <p>{allCourseDetails?.descriptions}</p>
                  </div>
                  <div className="items">
                  {allCourseDetails?.categories_for_department?.map((catname,index)=>
                   <>{catname?.tools_for_category.length>0? <div className="singleItems" key={catname.id}>
                                <ul>
                                  <li>
                                    <Link onClick={handleSign}><span className="material-icons">done</span>{catname?.name}</Link>
                                    </li>
                                </ul>
                            </div>:null}</>
                  
                  )}
                            
                            
                        </div>
                    
              </div>
          </div>  
        </>
    )
}

export default CategoryGetting;