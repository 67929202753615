import React from 'react'

import { Materials } from '../Home/Materials/Materials'
import './Category.css'
import { Link } from 'react-router-dom'
import No from '../../images/no.png'



const Category = ({singleCat}) => {
      const handleSign=()=>{
        window.location.assign("https://app.cit.tools/");
    }
    return (
        <>
        <div className="categoryMain">
        <div className="container ">
            <div className="desktop">
            {singleCat?.tools_for_category.length>0?<h3>{singleCat?.name} </h3>:null}
            
            
            {singleCat?.tools_for_category.length>0?<>
                <div className="materialCat">
                {/* <div className="materialMargin titleMain">   
                </div> */}
                {singleCat?.tools_for_category.slice(0,4).map(tools=>
                    <div className="materialItems"><Materials img={tools?.toolThumbnail?tools?.toolThumbnail:No} title={tools?.name.substring(0,15)+ "..."} mr="materialMargin"/></div>
                )}
                
            </div>
            </>:null}
            {singleCat?.tools_for_category.length>0?<>
                <Link className="allSeeBtn" onClick={handleSign}>View All </Link>
            <div className="categoryBorder"></div>
            </>:null}
            </div>
            <div className="mobileViewSlide">
                <>
                {singleCat?.tools_for_category.length>0?
                    <div className="allMaterialHeader">
             <div>
             {singleCat?.tools_for_category.length>0?<h3>{singleCat?.name} </h3>:null}
                </div>
                {singleCat?.tools_for_category.length>0?<div><span>View all</span> <span className="material-icons">keyboard_arrow_right</span></div>:null}
             
         </div>:null}
               
         {singleCat?.tools_for_category.length>0?<>
                <div className="materialCat">
                {/* <div className="materialMargin titleMain">   
                </div> */}
               
               
                {singleCat?.tools_for_category.slice(0,4).map(tools=>
                 <div className="materialItems">
                    <Materials img={tools?.toolThumbnail?tools?.toolThumbnail:No} title={tools?.name.substring(0,15)+ "..."} mr="materialMargin"/>
               </div>
                   
                )}
                
            </div>
            </>:null}
            
                </>
            </div>
           
        </div>
     </div>
        </>
    )
}

export default Category;