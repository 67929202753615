import React from 'react';
import * as AiIcons from 'react-icons/ai';
import { MdSecurity } from "react-icons/md";
import { SiAdobeindesign } from "react-icons/si";
import { HiOutlineDocumentSearch } from "react-icons/hi";
import { GrGraphQl } from "react-icons/gr";
import { CgWebsite } from "react-icons/cg";
import { GiBottomRight3DArrow } from "react-icons/gi";


export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Graphics Design',
    path: '/Graphics-design',
    icon: <GrGraphQl />,
    cName: 'nav-text'
  },
  {
    title: 'Web Design',
    path: '/web-design',
    icon: <CgWebsite />,
    cName: 'nav-text'
  },
  {
    title: '3D Animation',
    path: '/3d-animation',
    icon: <GiBottomRight3DArrow />,
    cName: 'nav-text'
  },
  {
    title: 'Digital Marketing',
    path: '/digital-marketing',
    icon: <HiOutlineDocumentSearch />,
    cName: 'nav-text'
  },
  {
    title: 'Cyber Security',
    path: '/cyber-security',
    icon: <MdSecurity/>,
    cName: 'nav-text'
  },
  {
    title: 'Interior Design',
    path: '/interior-design',
    icon: <SiAdobeindesign />,
    cName: 'nav-text'
  },
  {
    title: 'Motion Graphics',
    path: '/',
    icon: <GrGraphQl />,
    cName: 'nav-text'
  },
  {
    title: 'UI/Ux',
    path: '/',
    icon: <CgWebsite />,
    cName: 'nav-text'
  },
  {
    title: 'Web Development',
    path: '/web-development',
    icon: <GiBottomRight3DArrow />,
    cName: 'nav-text'
  },
  {
    title: 'Robotics',
    path: '/',
    icon: <HiOutlineDocumentSearch />,
    cName: 'nav-text'
  },
  {
    title: 'Networking',
    path: '/',
    icon: <MdSecurity/>,
    cName: 'nav-text'
  },
  {
    title: 'English',
    path: '/',
    icon: <SiAdobeindesign />,
    cName: 'nav-text'
  }
];
