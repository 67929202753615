import React from 'react';
import ReactDOM from 'react-dom';
import "keen-slider/keen-slider.min.css"
import 'antd/dist/antd.css';
import './index.css';
import App from './App';

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}

