import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Materials } from '../Materials/Materials'
import './AllMaterials.css'
import No from '../../../images/no.png'
import { CourseData } from '../../../App'
import { Link } from 'react-router-dom'

const AllMaterials = ({
    title11,title22,title33
}) => {
    // const [latestTools,setLatestTools]=useState([])
    // const handleCourse = async() => {
    //     const {data}= await axios.get(`https://api.cit.tools/api/frontendApi/latestTools`)
    //   if(data){
    //     setLatestTools(data?.latestTools)
    //   }
    // }
  
    //   const [popularTools,setPopularTools]=useState([])
    // const handlePopularTools = async() => {
    //     const {data}= await axios.get(`https://api.cit.tools/api/frontendApi/popularTools`)
    //   if(data){
    //     setPopularTools(data?.latestTools)
    //   }
    // }
    const [selectCourse,setSelectCourse,latestTools,setLatestTools,popularTools,setPopularTools] = useContext(CourseData)
    const handleSign=()=>{
      window.location.assign("https://app.cit.tools/");
  }
    // useEffect(() => {
    //     handleCourse()
    //     handlePopularTools()
    // }, [])
    return (
        <>
        <div className="container">
         <div className="allMaterialHeader">
             <div><h3>{title11}</h3></div>
             <div ><Link  onClick={handleSign}><span>View all</span></Link> <span className="material-icons">keyboard_arrow_right</span></div>
         </div>
            <div className="materialCat">
              
            {popularTools?.map(tools=>
                <div className="materialItems" key={tools.id}>
            <Materials img={tools?.toolThumbnail?tools?.toolThumbnail:No} title={tools?.name.substring(0,15)+ "..."} mr="materialMargin"/>
            </div>
            )}
            </div>
            <div className="allMaterialHeader">
             <div><h3>{title22}</h3></div>
             <div ><Link to="" onClick={handleSign} ><span>View all</span></Link>
             <span className="material-icons">keyboard_arrow_right</span>
             </div>
         </div>
            <div className="materialCat">
            {latestTools.map(tools=>
                    <div className="materialItems" key={tools.id}>
               <Materials img={tools?.toolThumbnail?tools?.toolThumbnail:No} title={tools?.name.substring(0,15)+ "..."} mr="materialMargin"/>
               </div>
                )}
            
            </div>
            <div className="allMaterialHeader">
             <div><h3>{title33}</h3></div>
             <div ><Link onClick={handleSign} ><span>View all</span></Link>
             <span className="material-icons">keyboard_arrow_right</span>
             </div>
         </div>
            <div className="materialCat">
            {latestTools.map(tools=>
                    <div className="materialItems" key={tools.id}>
               <Materials img={tools?.toolThumbnail?tools?.toolThumbnail:No} title={tools?.name.substring(0,15) + "..."} mr="materialMargin"/>
               </div>
                )}
         
            </div>

        </div>
            
        </>
    )
}

export default AllMaterials;