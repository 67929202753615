import React, { Suspense, lazy, useState } from "react";
import { Code } from "react-content-loader";
import { Button, Modal } from "antd";
const CourseSelect = lazy(() => import("../CourseSelect/CourseSelect"));
const VideoTalk = lazy(() => import("../VideoTalk/VideoTalk"));
const AllMaterials = lazy(() => import("../AllMaterials/AllMaterials"));
const StudentSays = lazy(() => import("../StudentSays/StudentSays"));
const IosCertifed = lazy(() => import("../IosCertified/IosCertifed"));
const OurConcerns = lazy(() => import("../OurConcerns/OurConcerns"));
const AllTipTicks = lazy(() => import("../AllTipTicks/AllTipTicks"));
const Footer = lazy(() => import("../../Footer/Footer"));

const Home = () => {
  // const [open, setOpen] = useState(true);

  return (
    <Suspense fallback={<Code />}>
      <CourseSelect />
      {/* <Modal centered visible={open} closable={false}>
        <div className="container text-center">
          <div className="row justify-content-center align-items-center min-vh-100">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="card p-4 shadow-sm">
                <div className="card-body">
                  <h1 className="display-4">We'll be back soon!</h1>
                  <p class="lead">
                    Our website is currently undergoing scheduled maintenance.
                    Thank you for your patience and understanding.
                  </p>

                  <p>
                    We are working hard to improve our site and bring you a
                    better experience. Please check back later.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      <VideoTalk
        Inputvideo="https://www.youtube.com/watch?v=64ATRTat-ak"
        title="দেখে নিন কিভাবে cit.tools আপনাকে একজন টপ রেটেড সেলার হতে সাহায্য করবে"
        text="দারুন দারুন অসংখ্য প্রিমিয়াম সফটওয়্যার ও  রিসোর্স। "
        text1=" ব্যবহার করার সুযোগ পাচ্ছেন cit.tools  এ."
        text2="আমাদের শিক্ষার্থীরা লাইফ টাইম এর জন্যই এই সার্ভিসটি পাবে।"
        readMoreText="অর্থাৎ ক্রিয়েটিভ আইটির যেকোনো কোর্সের সাথেই আপনি এই সুবিধাটি নিতে পারবেন।
                        এই প্ল্যাটফর্মে থাকছে আনলিমিডেট প্রিমিয়াম সব টুলস, প্রিমিয়াম সফটওয়্যার, রিসোর্স। প্রতিনিয়তই নতুন নতুন সব আপডেটেড পাবেন এই প্ল্যাটফর্মে। আনলিমিডেট রিসোর্সের পাশাপশি থাকছে আনলিমিডেট ডাউনলোডের সুযোগ।
                        তাই বলা যায় শিক্ষার্থী হবেন একবার কিন্তু রিসোর্স ব্যবহার করতে পারবেন অসংখ্যবার। শুধু তাই নয় সবগুলো রিসোর্স একসাথে থাকার কারণে আপনার সময়টা আর নষ্ট হচ্ছে না। ফলে আপনার সফলতার যাত্রাটি আরো অনেক সহজ হয়ে যাবে। চলুন ভিডিওতে এমনই একজন টপ রেটেড সেলারের কাছ থেকেই শুনে নেওয়া যাক  তার সফলতার যাত্রা পথে কতটা অবদান রেখেছে cit.tools
                        "
      />
      <AllMaterials
        title11=" জনপ্রিয় টুলস"
        title22="নতুন সফটওয়্যারগুলো"
        title33="নতুন যা কিছু যুক্ত করা হয়েছে"
      />
      <StudentSays />
      <IosCertifed />
      <OurConcerns />
      <AllTipTicks />
      <Footer />
    </Suspense>
  );
};
export default Home;
