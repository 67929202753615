import React from 'react'
import { Link } from 'react-router-dom'
import './TipTicks.css'

export const TipTicks = ({img,title,text}) => {
    return (
        <>
                <div className="ticsSingle">
                    <div className="tipsImg">
                        <img src={img} alt="" />
                    </div>
                    <div className="tipsText">
                        <h4>{title}</h4>
                        <p>{text}</p>
                        <Link><span>Read More</span></Link>
                    </div>
                </div>
            
        </>
    )
}
