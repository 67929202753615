import React from 'react'

import { TipTicks } from '../TipTicks/TipTicks'
import t1 from '../../../images/t1.png'
import t2 from '../../../images/t2.png'
import t3 from '../../../images/t3.png'
import './AllTipTicks.css'

 const AllTipTicks = () => {
    
    return (
        <>
         <div className="container tiptikcsMain">
         <div className="allMaterialHeader">
             <div><h3>Tips & Tricks</h3></div>
             <div><span>View all</span> <span className="material-icons">keyboard_arrow_right</span></div>
         </div>
             <div className="alltip">
                 <div className="tips">
                 <TipTicks img={t1} 
                 title="Web Design"
                 text="অনলাইনে আপনার উপস্থিতি প্রকাশ করে সুন্দর আর কার্যকর ডিজাইনের ওয়েবসাইট। "
                 />
                 </div>
                 <div className="tips">
                 <TipTicks img={t2} 
                 title="Digital Marketing"
                 text="ব্যবসায়ী হিসেবে আপনার ব্যবসাকে আরও সামনে এগিয়ে নিতে ডিজিটাল মার্কেটিং এখন সময়ের দাবী।"
                 />
                 </div>
                 <div className="tips">
                 <TipTicks img={t3} 
                 title="3D"
                 text="দৃষ্টিনন্দন নকশার মাধ্যমে দর্শক বা গ্রাহককে আকৃষ্ট করার জন্য গ্রাফিক ডিজাইনের বিকল্প নেই। "
                 />
                 </div>
             </div>
         </div>   
        </>
    )
}
export default AllTipTicks;