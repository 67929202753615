import React from 'react'
import video from '../../../images/video.png'
import './VideoTalk.css'
import ReactPlayer from 'react-player'
import { Link } from 'react-router-dom'
import ShowMoreText from "react-show-more-text";
import { FaPlayCircle } from "react-icons/fa";


 const VideoTalk = ({title,title1,text,text1,text2,name,courseName,readMoreText,Inputvideo,bannerClass,bannerImg,h1title}) => {
    return (
        <>
    <div className={name?"videoMainbg animate__animated animate__zoomIn":"animate__animated animate__zoomIn"} id={bannerClass===true?"bannerActive":""} style={{backgroundImage:`url(${bannerImg})`}}>
        <div className="container">
            <div className={name?"videoMain1 ":"videoMain"}>
                <div className="videopart">
                    <div className="videoText animate__animated animate__zoomIn">
                    {
                        courseName?<div className={bannerClass===true?"colorBtn":""}><Link to="/" className={bannerClass===true?"color2 ":""}>Home</Link> <small className={bannerClass===true?"color2":""}>{courseName}</small></div>:null
                    }
                    {bannerClass===true?
                        <h1 className={bannerClass===true?"colorh1":""}>{h1title} </h1>:null
                    }
                  
                        <h3 className={bannerClass===true?"color colorh3":""}>
                        {title} 
                        </h3>
                        <p className={bannerClass===true?"color":""}>{text} <br /> {text1}<br />  <ShowMoreText
                                lines={1}
                                more="Read more"
                                less="Read less"
                                className="content-css"
                                
                                expanded={false}
                                truncatedEndingComponent={"...."}
                                
                        >
                       {text2} {readMoreText}
                        </ShowMoreText> </p>

                    </div>
                    { Inputvideo?.split(':').length>1  &&
                    <div className="videoplay animate__animated animate__zoomIn">
                    <ReactPlayer className="player " 
                    url={Inputvideo}
                    config={{
                        youtube: {
                        playerVars: { showinfo: true }
                        
                        }, 
                         
                    }}
                    controls={true}
                    />
                    </div>
                }
                </div>
            </div>
        </div>
     </div>
        </>
    )
}
export default VideoTalk;