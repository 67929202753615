import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../../images/logo.png";
import fb from "../../images/fb.png";
import yt from "../../images/yt.png";
import linked from "../../images/in.png";
import insta from "../../images/insta.png";
// import tw from "../../images/tw.png";
// import pn from "../../images/pn.png";

const Footer = () => {
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [toggle3, setToggle3] = useState(false);
  return (
    <>
      <div className="footerSection">
        <div className="container">
          <div className="footerLogo">
            <div className="logoF">
              <Link>
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="socialIcons">
                <a href="https://www.facebook.com/CreativeITInstitute" target="_blank">
                    <div className="icons">
                        <div className="socialIcon">
                        <img src={fb} alt="" />
                        </div>
                    </div>
                </a>
                <a href="https://www.youtube.com/c/CreativeITInstitute" target="_blank">
                    <div className="icons">
                        <img src={yt} alt="" />
                    </div>
                </a>
                <a href="https://www.linkedin.com/company/creativeitinstitute/mycompany/" target="_blank">
                    <div className="icons">
                        <img src={linked} alt="" />
                    </div>
                </a>
                <a href="https://instagram.com/creativeitinstitutebd?igshid=YmMyMTA2M2Y=" target="_blank">
                    <div className="icons">
                        <img src={insta} alt="" />
                    </div>
                </a>
              {/* <div className="icons">
                <img src={tw} alt="" />
              </div>
              <div className="icons">
                <img src={pn} alt="" />
              </div> */}
            </div>
          </div>
          <div className="mainFooter">
            <div className="footerSection1">
              <div className="mobileView">
                <h4>Contact Us</h4>
                <div
                  className="cotactusIcon"
                  onClick={() => setToggle(!toggle)}
                >
                  {toggle ? (
                    <span className="material-icons">keyboard_arrow_up</span>
                  ) : (
                    <span className="material-icons">keyboard_arrow_down</span>
                  )}
                </div>
              </div>
              <h4>Contact Us</h4>
              <div
                className={
                  toggle ? "footerPadding " : "footerPadding displayHide"
                }
              >
                <p>
                  Head Office:
                  <br />
                  Momtaz Plaza (4th Floor) <br /> House - 07, Road - 04,
                  Dhanmondi, Dhaka
                </p>
                <p>
                  Campus 2:
                  <br />
                  Meher Plaza (3rd Floor),
                  <br /> House - 13/A, Road - 05, Dhanmondi, Dhaka
                </p>
              </div>
            </div>

            <div className="footerS2">
              <div className="mobileView">
                <h4>Our Courses</h4>
                <div onClick={() => setToggle2(!toggle2)}>
                  {toggle2 ? (
                    <span className="material-icons">keyboard_arrow_up</span>
                  ) : (
                    <span className="material-icons">keyboard_arrow_down</span>
                  )}
                </div>
              </div>
              <div className="footerSection2" id={toggle2 ? "" : "displayHide"}>
                <div>
                  <ul>
                    <h4>Our Courses</h4>

                    <li><a href="https://creativeitinstitute.com/courses/professional-graphics-design" target="_blank">Graphic Design</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/image-editing" target="_blank">Digital Image Editing</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/ux-ui-design" target="_blank">UI/UX Design</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/motion-graphics" target="_blank">Motion Graphics</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/web-design" target="_blank">Web Design</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/web-development" target="_blank">Web Development</a></li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li><a href="https://creativeitinstitute.com/department/digital-marketing" target="_blank">Digital Marketing</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/affiliate-marketing" target="_blank">SEO &amp; Affiliate Marketing</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/social-media-marketing" target="_blank">Social Media Marketing</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/app-development" target="_blank">App Development</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/mern-stack-development" target="_blank">MERN Stack Development</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/python-machine-learning" target="_blank">Python with Machine Learning</a></li>
                  </ul>
                </div>

                <div>
                  <ul>
                    <li><a href="https://creativeitinstitute.com/courses/professional-interior-exterior-design" target="_blank">Interior &amp; Exterior Design</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/3d-product-modeling" target="_blank">3D Animation</a></li>
                    <li><a href="https://creativeitinstitute.com/department/networking" target="_blank">CCNA, MicroTik &amp; CompTIA A+</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/spoken-english" target="_blank">Spoken English</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/business-english" target="_blank">Business English</a></li>
                    <li><a href="https://creativeitinstitute.com/courses/video-editing" target="_blank">Professional Video Editing</a></li>
                    {/* <li><a href="javascript:void(0)">Robotics</a></li> */}
                  </ul>
                </div>
              </div>
            </div>

            <div className="footerSection3">
              <div className="mobileView">
                <h4>Quick Links</h4>
                <div onClick={() => setToggle3(!toggle3)}>
                  {toggle3 ? (
                    <span className="material-icons">keyboard_arrow_up</span>
                  ) : (
                    <span className="material-icons">keyboard_arrow_down</span>
                  )}
                </div>
              </div>
              <ul className={toggle3 ? "" : "displayHide"}>
                <h4>Quick Links</h4>
                <li><a href="https://creativeitinstitute.com/about-us" target="_blank">About Us</a></li>
                <li><a href="https://creativeitinstitute.com/gallery" target="_blank">Gallery</a></li>
                <li><a href="https://creativeitinstitute.com/job-placement" target="_blank">Job Placement</a></li>
                <li><a href="https://creativeitinstitute.com/student-feedback" target="_blank">Student's Feedback</a></li>
                <li><a href="https://creativeitinstitute.com/freelancing" target="_blank">Freelancing Success</a></li>
                <li><a href="https://creativeitinstitute.com/contact-us" target="_blank">Contact Us</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <h5>&copy; 2021 CIT.Tools. All rights reserved.</h5>
        </div>
      </div>
    </>
  );
};

export default Footer;
