import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { Code } from 'react-content-loader'
// import g1 from './images/material1.png'
// import g2 from './images/mg2.png'
// import g3 from './images/mg3.png'
// import g4 from './images/mg4.png'
// import mc1 from './images/mc1.png'
// import mc2 from './images/mc2.png'
// import mc3 from './images/mc3.png'
// import mc4 from './images/mc4.png'
// import wb1 from './images/wb1.png'
// import wb2 from './images/wb2.png'
// import wb3 from './images/wb3.png'
// import wb4 from './images/wb4.png'
// import { SingleDetails } from "./Components/pages/SingleDetails";
// import { MobileSingleDetails } from "./Components/pages/MobileSingleDetails";

import ScrollToTop from "./Components/RouterScrollTop/RouterScrollTop";
// import { Modal} from 'antd';
// import modalImg from './images/modal.jpg'
import Header from "./Components/Home/Header/Header";
import Home from "./Components/Home/Home/Home";
import CourseDetails from "./Components/pages/CourseDetails";

export const CourseData = createContext();

function App() {
  const [selectCourse, setSelectCourse] = useState([]);
  const [latestTools, setLatestTools] = useState([]);
  const [popularTools, setPopularTools] = useState([]);
  //  const [visible,setVisible] = useState(true);
  //   const handleOk = e => {
  //     setVisible(false)
  //   };

  //   const handleCancel = e => {

  //     setVisible(false)
  //   };

  // It will be executed before rendering

  return (
    <>
      <CourseData.Provider
        value={[
          selectCourse,
          setSelectCourse,
          latestTools,
          setLatestTools,
          popularTools,
          setPopularTools,
        ]}
      >
        <Router>
          <Header />
          <ScrollToTop />
          <Switch>
            <Route exact path="/">
              <>
                {/* <Modal
         centered
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <img src={modalImg} alt=""  width="100%" onClick={handleCancel} className="modalImg"/>
        </Modal> */}
                <Home />
              </>
            </Route>
            <Route path="/:postId/:name">
              <CourseDetails />
            </Route>

            {/* <Route path="/items-details">

              <Header/>
             <div className="desktop">
             <SingleDetails
               p1={g1} p2={g2} p3={g3} p4={g4}
              p5={mc1} p6={mc2} p7={mc3} p8={mc4}
             p9={wb1} p10={wb2} p11={wb3}  p12={wb4}

             />
             </div>
             <div className="mobileViewSlide">
             <MobileSingleDetails
            p1={g1} p2={g2} p3={g3} p4={g4}
              p5={mc1} p6={mc2} p7={mc3} p8={mc4}
             p9={wb1} p10={wb2} p11={wb3}  p12={wb4}
             />
             </div>

          </Route> */}
            {/* <Redirect to="/"/> */}
          </Switch>
        </Router>
      </CourseData.Provider>
    </>
  );
}

export default App;
