import React, { useContext, useState } from 'react';
import * as AiIcons from 'react-icons/ai';
import { IoEllipsisVertical } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './MobileMenu.css'
import { IconContext } from 'react-icons';
import logo from '../../../../images/logo.png'
import { CourseData } from '../../../../App';

function MobileMenu() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const [checkdone,setCheckDone]=useState(false)
  const [selectCourse,setSelectCourse] = useContext(CourseData)

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className='navbar'>
        <div className="mobileNav">
        <Link className="navbar-brand" to="/" id={checkdone?"checkOk":""}>
                        <img src={logo} alt="" width="120px" />
                    </Link>
                            <div className="box"  id={checkdone?"":"marginOk"}>
                           
                        <input type="checkbox" id="check"/>
                        <div className="search-box">
                        <input type="text" placeholder="Type here..."/>
                        <label for="check" className="icon" onClick={()=>setCheckDone(!checkdone)}>
                        <span className="material-icons">search</span>
                        </label>
                        </div>
                     </div>
          <Link to='#' className='menu-bars'>
            <IoEllipsisVertical onClick={showSidebar} />
          </Link>
          </div>
        </div>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className='navbar-toggle'>
              <Link to='#' className='menu-bars'>
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {selectCourse.map((item,index) => {
              return (
                <li key={index} className="nav-text">
                  <Link to={`${item.id}/${item.name}`}> 
                    <span>{item.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default MobileMenu;