import React from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import './Materials.css'

export const Materials = ({img,title,mr,mr2}) => {
    const handleSign=()=>{
        window.location.assign("https://app.cit.tools/");
    }
    return (
        <>
            
             
               <div className={`materialMain ${mr} ${mr2}`}>
               <Link  className="itemsdetaisbtn" onClick={handleSign}>
               
                   <div className="itemsImg" style={{backgroundImage:`url(${img})`}}></div>
                     <div className="materialFooter">
                        <span>{title}</span>
                        <div className="downloadimg"><span className="material-icons">file_download</span></div>
                     </div>
                     </Link>
                </div>
                 
            
        </>
    )
}
