import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../../images/logo.png'
import avatar from '../../../images/avatar.png'
import './Header.css'
import MobileMenu from './MobileMenu/MobileMenu'
import { CourseData } from '../../../App'


const Header = () => {
    const [selectCourse,setSelectCourse] = useContext(CourseData)
    const handleSign=()=>{
        window.location.assign('https://app.cit.tools/');
    }
    return (
        <>
        <div className="navs"></div>
     <nav className="navbar" >
        <div className="container" >
                    <div className="navMain">
                    <Link className="navbar-brand" to="/" refresh={true}>
                        <img src={logo} alt="" width="120px" />
                    </Link>
                             <div className="navSearch">
                             <div className="dropdown">
                                <button className="btn  dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                All Products 
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {selectCourse.map((course,index)=><Link className="dropdown-item" to={`/${course.id}/${course.name}`} key={index}>{course?.name}</Link>  )}
                                    
                                    {/* <Link className="dropdown-item" to="/web-design" >Web Design</Link>
                                    <Link className="dropdown-item" to="/3d-animation" >3D Animation</Link>
                                    <Link className="dropdown-item" to="/digital-marketing">Digital Marketing</Link>
                                    <Link className="dropdown-item" to="/cyber-security">Cyber Security</Link>
                                    <Link className="dropdown-item" to="/interior-design">Interior Design</Link>
                                    <Link className="dropdown-item" to="/motion">Motion Graphics</Link>
                                    <Link className="dropdown-item" to="/ui-ux">UI/UX</Link>
                                    <Link className="dropdown-item" to="/web-development">Web Development</Link>
                                    <Link className="dropdown-item" to="/robotics">Robotics</Link>
                                    <Link className="dropdown-item" to="/interior-design">networking</Link> */}
                                </div>
                                </div>
                             <input type="search" placeholder="Search Here..."/>
                             </div>
                             <div className="navProfile" onClick={handleSign}>
                             <img src={avatar} alt="" />
                                 <span>Sign In</span>
                             </div>
                    </div>
                    {/* <div className="mobileNav"> */}
                    {/* <Link className="navbar-brand" to="/">
                        <img src={logo} alt="" width="120px" />
                    </Link>
                    <div>
                    <span className="material-icons">search</span>
                    <span className="material-icons">more_vert</span>
                    </div> */}
                    
                     {/* <span className="material-icons">more_vert</span> */}
                    <div className="mobilemenuhide">
                    <MobileMenu/>
                    </div>
                   
               
                  
                    {/* <MobileMenu pageWrapId={"navMain"} outerContainerId={"navsBar"}/> */}
        </div>
</nav>
        </>
    )
}

export default Header;